const catalog =[
{
    id:1,
    image:'/scroll_image/image_s_1.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
},

{
    id:2,
    image:'/scroll_image/image_s_2.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 390"
    
},
{
    id:3,
    image:'/scroll_image/image_s_3.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 533"
    
},
{
    id:4,
    image:'/scroll_image/image_s_4.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 588"
    
},
{
    id:5,
    image:'/scroll_image/image_s_5.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 533"
    
},
{
    id:6,
    image:'/scroll_image/image_s_6.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 390"
    
},
{
    id:7,
    image:'/scroll_image/image_s_7.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 350"
    
},
{
    id:8,
    image:'/scroll_image/image_s_8.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
},
{
    id:9,
    image:'/scroll_image/image_s_9.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 800"
    
},
{
    id:10,
    image:'/scroll_image/image_s_10.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
},
{
    id:12,
    image:'/scroll_image/image_s_12.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 790"
    
},
{
    id:13,
    image:'/scroll_image/image_s_13.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
},
{
    id:14,
    image:'/scroll_image/image_s_14.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 490"
    
}
,
{
    id:15,
    image:'/scroll_image/image_s_15.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 540"
    
}
,
{
    id:16,
    image:'/scroll_image/image_s_16.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
}
,
{
    id:17,
    image:'/scroll_image/image_s_17.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 539"
    
}
,
{
    id:17,
    image:'/scroll_image/image_s_17.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 800"
    
}
,
{
    id:19,
    image:'/scroll_image/image_s_19.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 590"
    
}
,
{
    id:20,
    image:'/scroll_image/image_s_20.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 530"
    
}
,
{
    id:21,
    image:'/scroll_image/image_s_21.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 352"
    
}
,
{
    id:23,
    image:'/scroll_image/image_s_23.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 800"
    
}
,
{
    id:24,
    image:'/scroll_image/image_s_24.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 790"
    
}
,
{
    id:25,
    image:'/scroll_image/image_s_25.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 533"
    
}
,
{
    id:26,
    image:'/scroll_image/image_s_26.jpg',
    price:2500,
    description:"Price per 100 sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_31.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_32.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_33.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_34.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_35.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_36.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_37.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_38.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_39.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_40.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_41.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_42.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_43.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_44.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_45.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_46.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_47.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_48.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_49.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_50.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}
,
{
    id:31,
    image:'/scroll_image/image_s_51.jpg',
    price:2500,
    description:"Price per sq.ft = 400"
    
}

]
export default catalog; 