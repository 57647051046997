const Product_Work =[
    {
        id:1,
        image:'/Work_image/image1.jpg',
        price:'GRC BHUTAN',
        description:"this is good catalog"
        
    },
    
    {
        id:2,
        image:'/Work_image/image2.jpg',
        price:'GRC PANEL TRIPURA BHAWANN CHANKPURI',
        description:"this is good catalog"
        
    },
    {
        id:3,
        image:'/Work_image/image3.jpg',
        price:'BANQUET HALL DELHI',
        description:"this is good catalog"
        
    },
    {
        id:4,
        image:'/Work_image/image4.jpg',
        price:'CPWD CG BUILDING DELHI',
        description:"this is good catalog"
        
    },
    {
        id:5,
        image:'/Work_image/image5.jpg',
        price:'MGF GURGAON BILAS PROJECT',
        description:"this is good catalog"
        
    },
    {
        id:6,
        image:'/Work_image/image6.jpg',
        price:'ITC GRAND BHARAT',
        description:"this is good catalog"
        
    },
    {
        id:7,
        image:'/Work_image/image7.jpg',
        price:'JAISALMAR AIRPORT',
        description:"this is good catalog"
        
    },
    {
        id:8,
        image:'/Work_image/image8.jpg',
        price:'BANQUET HALL DELHI',
        description:"this is good catalog"
        
    },
    {
        id:9,
        image:'/Work_image/image9.jpg',
        price:'ITC, GRAND BHARAT',
        description:"this is good catalog"
        
    },
    {
        id:10,
        image:'/Work_image/image10.jpg',
        price:'COLUMN CAPA CAPITAL',
        description:"this is good catalog"
        
    },
    {
        id:11,
        image:'/Work_image/image11.jpg',
        price:'A PROJECT AT DEHRADUN',
        description:"this is good catalog"
        
    },
    {
        id:12,
        image:'/Work_image/image12.jpg',
        price:'ASHIMA MALL BHOPAL ',
        description:"this is good catalog"
        
    },
    {
        id:13,
        image:'/Work_image/image13.jpg',
        price:'L&T EXHIBITION CENTRE GANDHI NAGAR ',
        description:"this is good catalog"
        
    },
    {
        id:14,
        image:'/Work_image/image14.jpg',
        price:'UNITECH VISTA VILAS',
        description:"this is good catalog"
        
    },
    {
        id:15,
        image:'/Work_image/image15.jpg',
        price:'ITC, GRAND BHARAT',
        description:"this is good catalog"
        
    },
    
    {
        id:16,
        image:'/Work_image/image16.jpg',
        price:'GRC, BHUTAN',
        description:"this is good catalog"
        
    },
    {
        id:17,
        image:'/Work_image/image17.jpg',
        price:'MGF FURGAON',
        description:"this is good catalog"
        
    },
    {
        id:18,
        image:'/Work_image/image18.jpg',
        price:'ARCHITECT SANJAY PURI: SHALIMAR MALL GANDHI NAGAR GUJRAT',
        description:"this is good catalog"
        
    },
    {
        id:19,
        image:'/Work_image/image19.jpg',
        price:'CPWD, R K PURAM',
        description:"this is good catalog"
        
    },
    {
        id:20,
        image:'/Work_image/image20.jpg',
        price:'AVI JAIPURIYA DPS GURGAON ',
        description:"this is good catalog"
        
    },
    {
        id:21,
        image:'/Work_image/image21.jpg',
        price:'JNU VASANT VIHAR',
        description:"this is good catalog"
        
    },
    {
        id:22,
        image:'/Work_image/image22.jpg',
        price:'JAISALMER AIRPORT',
        description:"this is good catalog"
        
    },
    
    {
        id:23,
        image:'/Work_image/image23.jpg',
        price:'GRC BHUTAN',
        description:"this is good catalog"
        
    },
    {
        id:24,
        image:'/Work_image/image24.jpg',
        price:'STEP BY STEP SCHOOL NOIDA',
        description:"this is good catalog"
        
    },
    {
        id:25,
        image:'/Work_image/image25.jpg',
        price:'DMRDC CKHAN MARKET METRO STATION DELHI',
        description:"this is good catalog"
        
    },
    {
        id:26,
        image:'/Work_image/image26.jpg',
        price:'HOUSING PROJECT, JHAJJAR',
        description:"this is good catalog"
        
    },
    {
        id:27,
        image:'/Work_image/image27.jpg',
        price:'ITC GRAND BHARAT',
        description:"this is good catalog"
        
    },
    {
        id:28,
        image:'/Work_image/image28.jpg',
        price:'JP GREEN NOIDA',
        description:"this is good catalog"
        
    },
    {
        id:29,
        image:'/Work_image/image29.jpg',
        price:'JAISALMER AIRPORT',
        description:"this is good catalog"
        
    },
    {
        id:30,
        image:'/Work_image/image30.jpg',
        price:'GRC BHUTAN',
        description:"this is good catalog"
        
    }
    ,
    {
        id:32,
        image:'/Work_image/image32.jpg',
        price:'House at Sonipat',
        description:"this is good catalog"
        
    }
    ,
    {
        id:33,
        image:'/Work_image/image33.jpg',
        price:'School at kanpur',
        description:"this is good catalog"
        
    }
    ]
    export default Product_Work; 